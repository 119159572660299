export function isKinApp() {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams) {
        return false;
    }

    const app = searchParams.get('app');
    if (!app) {
        return false;
    }

    return app.toLowerCase() === 'kinn';
}
