const classes = {
    overflowHidden: 'overflow-hidden',
};

class DetailsModal extends HTMLElement {
    constructor() {
        super();
        this.detailsContainer = this.querySelector('details');
        this.summaryToggle = this.querySelector('summary');

        this.detailsContainer.addEventListener('keyup',
            (event) => event.code.toUpperCase() === 'ESCAPE' && this.close());
        this.summaryToggle.addEventListener('click',
            this.onSummaryClick.bind(this));

        this.querySelector('button[type="button"]').addEventListener('click',
            this.close.bind(this));

        this.summaryToggle.setAttribute('role', 'button');

        document.addEventListener('open:search', (event) => {
            event?.preventDefault();
            event?.stopPropagation();
            event?.stopImmediatePropagation();

            this.openModal(this.querySelector('details'));
        });
    }

    isOpen() {
        return this.detailsContainer.hasAttribute('open');
    }

    onSummaryClick(event) {
        event?.preventDefault();

        event?.target?.closest('details')?.hasAttribute('open')
            ? this.close()
            : this.open(event);
    }

    onBodyClick(event) {
        if (![
            !this.contains(event?.target),
            event?.target?.classList?.contains('modal-overlay')
        ]?.includes(true)) {
            return;
        }

        this.close(false);
    }

    open(event) {
        this.openModal(event?.target?.closest('details'));
    }

    openModal(details) {
        this.onBodyClickEvent = this.onBodyClickEvent || this.onBodyClick.bind(this);

        details?.setAttribute('open', true);

        document?.body?.addEventListener('click', this.onBodyClickEvent.bind(this));

        document?.body?.classList?.add(classes?.overflowHidden);
    }

    close() {
        this.detailsContainer?.removeAttribute('open');

        document?.body?.removeEventListener('click', this.onBodyClickEvent.bind(this));

        document?.body?.classList?.remove(classes?.overflowHidden);
    }
}

export default DetailsModal;
