import axios from "axios";
import Swiper, { Navigation, Scrollbar, FreeMode } from "swiper";
import lozad from "lozad";

const selectors = {
    video: '[data-video]',
};

export function debounce(fn, wait) {
    let t;
    return (...args) => {
        clearTimeout(t);
        t = setTimeout(() => fn.apply(this, args), wait);
    };
}

class PredictiveSearch extends HTMLElement {
    constructor() {
        super();
        this.input = this.querySelector('input[type="search"]');
        this.predictiveSearchResults = this.querySelector('[data-predictive-search]');
        this.isOpen = false;
        this.limit = 10;

        this.setupEventListeners();
    }

    setupEventListeners() {
        const form = this.querySelector('form');
        form.addEventListener('submit', this.onFormSubmit.bind(this));

        this.input.addEventListener('input',
            debounce((event) => {
                this.onChange(event);
            }, 300).bind(this));

        this.input.addEventListener('focus', this.onFocus.bind(this));

        this.addEventListener('keyup', this.onKeyup.bind(this));
        this.addEventListener('keydown', this.onKeydown.bind(this));

        document.body.addEventListener('click', (e) => {
            const searchButton = e.target.closest('[data-search-button]');
            if (searchButton) {
                return;
            }

            const searchCloseBtn = e.target.closest('[data-search-close]');
            if (searchCloseBtn) {
                return;
            }

            const searchContainer = e.target.closest('[data-search-container]');
            if (searchContainer) {
                return;
            }

            this.close();
        });

        document.addEventListener('predictive-search:close', () => {
            this.close();
        });
    }

    getQuery() {
        return this.input.value.trim();
    }

    async onChange() {
        const searchTerm = this.getQuery();

        if (!searchTerm.length) {
            this.close(true);
            return;
        }

        this.open();
        await this.getSearchResults(searchTerm);
    }

    onFormSubmit(event) {
        if (
            !this.getQuery().length ||
            this.querySelector('[aria-selected="true"] a')
        )
            event.preventDefault();
    }

    async onFocus() {
        const searchTerm = this.getQuery();

        if (!searchTerm.length) return;

        if (this.getAttribute('results') === 'true') {
            this.open();
        } else {
            await this.getSearchResults(searchTerm);
        }
    }

    onKeyup(event) {
        if (!this.getQuery().length) this.close(true);
        event.preventDefault();

        switch (event.code) {
            case 'ArrowUp':
                this.switchOption('up');
                break;
            case 'ArrowDown':
                this.switchOption('down');
                break;
            case 'Enter':
                this.selectOption();
                break;
        }
    }

    onKeydown(event) {
        // Prevent the cursor from moving in the input when using the up and down arrow keys
        if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
            event.preventDefault();
        }
    }

    switchOption(direction) {
        if (!this.getAttribute('open')) return;

        const moveUp = direction === 'up';
        const selectedElement = this.querySelector('[aria-selected="true"]');
        const allElements = this.querySelectorAll('li');
        let activeElement = this.querySelector('li');

        if (moveUp && !selectedElement) return;

        this.statusElement.textContent = '';

        if (!moveUp && selectedElement) {
            activeElement = selectedElement.nextElementSibling || allElements[0];
        } else if (moveUp) {
            activeElement =
                selectedElement.previousElementSibling ||
                allElements[allElements.length - 1];
        }

        if (activeElement === selectedElement) return;

        activeElement.setAttribute('aria-selected', true);
        if (selectedElement)
            selectedElement.setAttribute('aria-selected', false);

        this.setLiveRegionText(activeElement.textContent);
        this.input.setAttribute('aria-activedescendant', activeElement.id);
    }

    selectOption() {
        const selectedProduct = this.querySelector('[aria-selected="true"] a, [aria-selected="true"] button');

        if (!selectedProduct) {
            return;
        }

        selectedProduct?.click();
    }

    async getSearchResults(searchTerm) {
        try {
            const html = await axios.get(window.Shopify.routes.predictive_search_url, {
                params: {
                    q: searchTerm,
                    'resources[limit]': this.limit,
                    section_id: 'predictive-search'
                },
                cors: false,
            }).then((response) => {
                return response.data;
            }) || "";

            const resultsMarkup = new DOMParser()
                ?.parseFromString(html, 'text/html')
                ?.querySelector('#shopify-section-predictive-search')
                ?.innerHTML;

            this.renderSearchResults(resultsMarkup);

            const sliderContainerElement = this.querySelector('[data-product-slider-container]');
            if (!sliderContainerElement) {
                return;
            }

            const sliderElement = sliderContainerElement?.querySelector('[data-product-slider]');
            if (!sliderElement) {
                return;
            }

            const previousArrowElement = sliderContainerElement?.querySelector('[data-swiper-prev]');
            const nextArrowElement = sliderContainerElement?.querySelector('[data-swiper-next]');
            const scrollbarElement = sliderContainerElement?.querySelector('[data-swiper-scrollbar]');

            new Swiper(sliderElement, {
                slidesPerView: "auto",
                ...(previousArrowElement && nextArrowElement ? {
                    navigation: {
                        prevEl: previousArrowElement,
                        nextEl: nextArrowElement,
                    },
                } : {}),
                ...(scrollbarElement ? {
                    scrollbar: {
                        el: scrollbarElement,
                    },
                } : {}),
                modules: [
                    Scrollbar,
                    Navigation,
                    FreeMode,
                ],
                on: {
                    init() {
                        const observer = lozad(selectors?.video, {
                            loaded(video) {
                                const autoplay = video?.dataset?.autoplay;

                                video?.classList?.add('video--loaded');

                                if (typeof autoplay !== "undefined") {
                                    video?.play();

                                    video?.setAttribute("autoplay", true);
                                }
                            }
                        });

                        if (!observer) {
                            return;
                        }

                        observer?.observe();
                    }
                }
            });
        } catch (error) {}
    }

    setLiveRegionText(statusText) {
        this.statusElement.setAttribute('aria-hidden', 'false');
        this.statusElement.textContent = statusText;

        setTimeout(() => {
            this.statusElement.setAttribute('aria-hidden', 'true');
        }, 1000);
    }

    renderSearchResults(resultsMarkup) {
        this.predictiveSearchResults.innerHTML = resultsMarkup;
        this.setAttribute('results', true);

        this.open();
    }

    open() {
        this.setAttribute('open', true);
        this.input.setAttribute('aria-expanded', true);
        this.isOpen = true;
    }

    close(clearSearchTerm = false) {
        if (clearSearchTerm) {
            this.input.value = '';
            this.removeAttribute('results');
        } else {
            this.input.blur();
        }

        const selected = this.querySelector('[aria-selected="true"]');

        if (!!selected) {
            selected?.setAttribute('aria-selected', "false");
        }

        this.input.setAttribute('aria-activedescendant', '');
        this.removeAttribute('open');
        this.input.setAttribute('aria-expanded', false);
        this.resultsMaxHeight = false;
        this.predictiveSearchResults.removeAttribute('style');

        this.isOpen = false;
    }
}

export default PredictiveSearch;
